/*
    Endeca configuration for the typeahead endeca instance. 
    
    See comments in site.endeca.instances.search.configuration    
*/

var site = site || {};
site.endeca = site.endeca || {};
site.endeca.instances = site.endeca.instances || {};
site.endeca.instances.typeahead = site.endeca.instances.typeahead || {};

jQuery(document).ready(function(){
    site.endeca.instances.typeahead.configuration = jQuery.extend( true, {}, site.endeca.configuration, {
        searchType: "typeahead", 
        followRedirects: false,
        minSearchLength: 3,
		    fullSearchBaseUrl: '/' + Drupal.settings.pathPrefix + "esearch?search=",
        
        nodes: {
            wrapper: jQuery('#typeahead-wrapper'),
            inputElements: jQuery('.predictive-search .search-term'),
            loading: jQuery('.loading', '#typeahead-wrapper')
        },
        
        queries: {
            product: {
                recordsPerPage: 5,
                recordFilters: ['discontinued', 'activeSkus', 'products'],
                rangeFilters: ['skuShoppable']
            }
        },
        
        results: {
            products: {
                baseClass: 'site.endeca.results',
                instanceArgs: {
                    parentNode: jQuery('.product-results', '#typeahead-wrapper'),
                    childTemplatePath: '/templates/endeca/typeahead/product-result.tmpl',
                    childTemplatePathSku: '/templates/endeca/typeahead/product-sku-result.tmpl'
                }, 
                configuration: {
                    limit: 5
                }
            },
            terms: {
                baseClass: 'site.endeca.results',
                instanceArgs: {
                    parentNode: jQuery('.term-results', '#typeahead-wrapper'),
                    templatePath: '/templates/endeca/typeahead/term-result.tmpl'
                }, 
                configuration: {
                    limit: 2
                }
            },
            seeResults: {
                baseClass: 'site.endeca.results',
                instanceArgs: {
                    parentNode: jQuery('.see-results', '#typeahead-wrapper'),
                    templatePath: '/templates/endeca/typeahead/term-result.tmpl'
                }
            }
        }
    });
    
    new site.endeca.instances.typeahead.control( site.endeca.instances.typeahead.configuration );
});
